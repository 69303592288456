import * as shadowCSS from 'bundle-text:./shadow.scss'

import { Provider as NativeProvider } from './providers/native/index.js'
import { Provider as YoutubeProvider } from './providers/youtube/index.js'
import { Provider as VimeoProvider } from './providers/vimeo/index.js'
import { Provider as GumletProvider } from './providers/gumlet/index.js'

import icon_play from 'bundle-text:./circle-play-solid.svg';
import icon_pause from 'bundle-text:./circle-pause-solid.svg';
import icon_volume_up from 'bundle-text:./volume-high-solid.svg';
import icon_volume_off from 'bundle-text:./volume-slash-solid.svg';

var __firstInterractionReady = false;
const __instances = new Set()

class hapVideo extends HTMLElement {
	connectedCallback() {
		// if shadowRoot is set there is nothing to do
		if (!!this.shadowRoot)
			return;

		this.flushStorage();

		__instances.add(this);

		this.style.display = this.style.display || "block";
		this.style.width = this.style.width || "100%";
		this.style.height = this.style.height || "100%";

		this.shadow = this.attachShadow({ mode: 'open' });

		this.shadow.innerHTML = `
			<style>${shadowCSS}</style>
			<div class="root" part="root">
				<div class="ctrls" part="ctrls">
					<button class="ctrls-play" part="ctrls-play" type="button">${icon_play}</button>
					<button class="ctrls-pause" part="ctrls-pause" type="button">${icon_pause}</button>
					<div class="ctrls-progress" part="ctrls-progress"></div>
					<button class="ctrls-mute" part="ctrls-mute" type="button">${icon_volume_up}</button>
					<button class="ctrls-unmute" part="ctrls-unmute" type="button">${icon_volume_off}</button>
					<div class="ctrls-volume" part="ctrls-volume"></div>
				</div>
				<div class="loader" part="loader"></div>
				<div class="gdpr" part="gdpr"></div>
				<div class="video" part="video">
					<div class="wrapper" part="wrapper"></div>
				</div>
				<div class="placeholder" part="placeholder"></div>
			</div>
			`;
		this.root = this.shadow.querySelector('.root');

		setTimeout(this.__init.bind(this), 500);
	}

	__init() {
		console.log('__init');

		this.settings = {
			provider: this.getAttribute('provider'),
			gdpr: this.hasAttribute('gdpr') ? true : false,
			cover: this.hasAttribute('cover') ? true : false,
			height: this.getAttribute('height') || "inherit",
			loop: this.__parseLoop(),
			autoplay: this.hasAttribute('autoplay') ? true : false,
			clickable: this.hasAttribute('clickable') ? true : false,
			muted: this.hasAttribute('muted') ? true : false,
			controls: this.getAttribute('controls') || this.hasAttribute('controls') || false,
			placeholder: this.getAttribute('placeholder') || this.hasAttribute('placeholder') || false,
			embed: {
				id: this.getAttribute('embed-id'),
				ratio: this.__parseRatio(this.getAttribute('embed-ratio')),
			}
		}

		if (this.settings.placeholder)
			this.root.querySelector('.placeholder').style.backgroundImage = 'url("' + this.settings.placeholder + '")'

		setTimeout(this.__rgpd_watcher.bind(this), 10);
	}

	__rgpd_watcher() {
		console.log('__rgpd_watcher');

		this.settings.gdpr = this.hasAttribute('gdpr') ? true : false

		if (this.settings.gdpr) {
			this.root.setAttribute('gdpr', '');
			setTimeout(this.__rgpd_watcher.bind(this), 250);
		}
		else {
			this.root.removeAttribute('gdpr');
			this.root.setAttribute('loading', '');
			setTimeout(this.__build.bind(this), 10);
		}
	}

	__build() {
		console.log('__build');

		this.__ready = false;
		this.__looping = false;
		this.__seeking = false;

		if (this.settings.provider == 'native')
			this.provider = new NativeProvider(this);
		else if (this.settings.provider == 'youtube')
			this.provider = new YoutubeProvider(this);
		else if (this.settings.provider == 'vimeo')
			this.provider = new VimeoProvider(this);
		else if (this.settings.provider == 'gumlet')
			this.provider = new GumletProvider(this);

		if (this.provider) {
			this.__fixRatio_Interval = setInterval(() => this.__fixRatio(), 100)

			this.provider.on('ready', function () {

				if (this.settings.provider == 'vimeo')
					setTimeout(() => this.root.removeAttribute('loading'), 500);
				else if (this.settings.provider == 'gumlet')
					setTimeout(() => this.root.removeAttribute('loading'), 500);
				else if (this.settings.provider == 'youtube')
					setTimeout(() => this.root.removeAttribute('loading'), 2000);
				else
					setTimeout(() => this.root.removeAttribute('loading'), 100);

				this.root.setAttribute('ready', "")
				this.__fixRatio(this.provider)

				this.__ready = true;
				this.onReady();

			}.bind(this));

			this.provider.on('progressChange', async () => {
				this.root.style.setProperty('--video-progression', (await this.getProgress()) + '%')

				this.__loopEnd();
				this.__seekEnd();

				// loop analysis
				let loop = this.settings.loop;
				if (typeof loop === 'object' && loop.length === 2) {
					let time = await this.getCurrentTime();
					if (time < loop[0] || time >= loop[1]) {
						this.__loopStart(loop[0]);
					}
				}
				if (loop === true) {
					// prevent unleash the hell on earth by looping before the last second
					if (await this.getCurrentTime() >= await this.getDuration() - 1) {
						this.__loopStart(0);
					}
				}
			})

			this.provider.on('volumeChange', async () => {
				this.root.style.setProperty('--video-volume', (await this.getVolume()) + '%')
			})

			if (this.settings.controls)
				this.root.setAttribute('controls', this.settings.controls);

			let wrapper = this.root.querySelector('.wrapper');
			let embed = this.provider.getEmbed();
			wrapper.appendChild(embed);
			//console.log(wrapper,embed);

			this.root.querySelector('.video').addEventListener('click', (event) => {
				if (this.settings.clickable)
					this.toggle()
			});

			this.root.querySelector('.ctrls').addEventListener('click', (event) => {
				event.stopPropagation();
			});

			this.root.querySelector('.ctrls-play').addEventListener('click', (event) => { this.play() });
			this.root.querySelector('.ctrls-pause').addEventListener('click', (event) => { this.pause() });
			this.root.querySelector('.ctrls-mute').addEventListener('click', (event) => { this.mute() });
			this.root.querySelector('.ctrls-unmute').addEventListener('click', (event) => { this.unMute() });

			this.root.querySelector('.ctrls-progress').addEventListener('click', (event) => {
				event.stopPropagation();
				const x = event.offsetX;
				const w = event.currentTarget.clientWidth;
				if (x >= 0 && x <= w) {
					this.setProgress(x / w * 100);
				}
			});

			this.root.querySelector('.ctrls-volume').addEventListener('click', (event) => {
				event.stopPropagation();
				const x = event.offsetX;
				const w = event.currentTarget.clientWidth;
				if (x >= 0 && x <= w) {
					this.setVolume(x / w * 100);
				}
			});
			window.addEventListener('resize', this.__fixRatio.bind(this))
		}
	}
	disconnectedCallback() {
		if (this.provider && typeof this.provider.destroyPlayer === 'function')
			this.provider.destroyPlayer();
	}

	async onReady() {
		if (this.__ready) {
			console.log('onReady');
			this.mute();

			if (this.settings.autoplay) {
				this.setCurrentTime(0)
				this.start();
			}
			else {
				this.pause();
			}

			if (__firstInterractionReady) {
				console.log('__firstInterractionReady');
				if (!this.settings.muted && await this.getVolume() == 0) {
					await this.unMute();
				}
			}
		}
	}

	flushStorage() {
		var store = JSON.parse(window.localStorage.getItem('hapi-video-userpref')) || {};
		Object.entries(store).forEach((data, k) => {
			if (data.time < Date.now() / 1000)
				delete store[k]
		})
		window.localStorage.setItem('hapi-video-userpref', JSON.stringify(store))
	}

	setStorage(k, v, ttl = 900) {
		k = this.settings.provider + '_' + this.settings.embed.id + '::' + k
		var store = JSON.parse(window.localStorage.getItem('hapi-video-userpref')) || {};
		store[k] = { value: v, expire: Date.now() / 1000 + ttl };
		window.localStorage.setItem('hapi-video-userpref', JSON.stringify(store))
	}
	getStorage(k, dflt = undefined) {
		k = this.settings.provider + '_' + this.settings.embed.id + '::' + k
		var store = JSON.parse(window.localStorage.getItem('hapi-video-userpref')) || {};
		if (store[k] && store[k].expire > Date.now() / 1000)
			return store[k].value !== undefined ? store[k].value : dflt;
		else if (store[k]) {
			delete store[k]
			window.localStorage.setItem('hapi-video-userpref', JSON.stringify(store))
		}
	}

	async toggle() {
		if (this.provider && typeof this.provider.playing === 'function') {
			if (await this.provider.playing())
				this.pause();
			else
				this.play();
		}
	}
	async start() {
		if (this.provider && typeof this.provider.play === 'function') {
			let loop = this.settings.loop;
			if (typeof loop === 'object' && loop.length === 2)
				this.setCurrentTime(loop[0])
			this.play()
		}
	}
	async play() {
		if (this.provider && typeof this.provider.play === 'function') {
			this.root.classList.add('playing');
			this.provider.play()
		}
	}
	async pause() {
		if (this.provider && typeof this.provider.pause === 'function') {
			this.root.classList.remove('playing');
			this.provider.pause()
		}
	}
	async mute() {
		if (this.provider && typeof this.provider.mute === 'function') {
			this.root.classList.add('muted');
			this.provider.mute()
		}
	}
	async unMute() {
		if (this.provider && typeof this.provider.unMute === 'function') {
			this.root.classList.remove('muted');
			this.provider.unMute(this.getStorage('volume', 50))
		}
	}
	async setVolume(volume) { // 0-100
		if (this.provider && typeof this.provider.setVolume === 'function') {
			this.setStorage('volume', volume);
			this.root.classList.remove('muted');
			this.provider.setVolume(volume)
		}
	}
	async getVolume() { // 0-100
		if (this.provider && typeof this.provider.getVolume === 'function') {
			return await this.provider.getVolume()
		}
		return 0;
	}
	async getProgress() { // 0-100
		let time = await this.getCurrentTime();
		let duration = await this.getDuration();

		let loop = this.settings.loop;
		if (typeof loop === 'object' && loop.length === 2) {
			duration = loop[1] - loop[0];
			time = time - loop[0];
		}

		if (duration)
			return 100 * time / duration;
		return 0;
	}
	async setProgress(p) { // 0-100
		let duration = await this.getDuration();
		let delta = 0;

		let loop = this.settings.loop;
		if (typeof loop === 'object' && loop.length === 2) {
			duration = loop[1] - loop[0];
			delta = loop[0];
		}

		this.setCurrentTime(delta + duration * p / 100)
	}

	async getRatio() {
		if (this.provider && typeof this.provider.getRatio === 'function')
			return await this.provider.getRatio()
		return '16:9';
	}
	async getDuration() {
		if (this.provider && typeof this.provider.getDuration === 'function')
			return await this.provider.getDuration()
		return 0;
	}
	async getCurrentTime() {
		if (this.provider && typeof this.provider.getCurrentTime === 'function')
			return await this.provider.getCurrentTime()
		return 0;
	}
	async setCurrentTime(seconds) {
		if (this.provider && typeof this.provider.setCurrentTime === 'function') {
			this.__seekStart();
			this.provider.setCurrentTime(seconds)
		}
	}

	__loopStart(seconds) {
		clearTimeout(this.__looping)
		this.__looping = setTimeout(() => this.root.setAttribute('looping', ""), 250);
		this.pause();
		this.setCurrentTime(seconds)
	}
	__loopEnd() {
		if (this.__looping) {
			clearTimeout(this.__looping)
			this.__looping = false;
			this.root.removeAttribute('looping')
			this.play()
		}
	}

	__seekStart() {
		clearTimeout(this.__seeking)
		this.__seeking = setTimeout(() => this.root.setAttribute('seeking', ""), 250);
	}
	__seekEnd() {
		if (this.__seeking) {
			clearTimeout(this.__seeking)
			this.__seeking = false;
			this.root.removeAttribute('seeking')
			this.play();
		}
	}

	__parseLoop() {
		let loopString = this.getAttribute('loop') || '';
		if (loopString && loopString.trim().match(/^[0-9.]+:[0-9.]+$/)) {
			let r = loopString.trim().split(':');
			return [parseFloat(r[0]), parseFloat(r[1])].sort((a, b) => a - b);
		}
		return this.hasAttribute('loop');
	}

	__parseRatio(ratioString) {
		if (ratioString && ratioString.trim().match(/^[0-9.]+:[0-9.]+$/)) {
			let r = ratioString.trim().split(':');
			let w = 1 * r[0];
			let h = 1 * r[1];
			if (h > 0)
				return w / h;
		}
		return 16 / 9;
	}

	async __fixRatio(provider) {
		try {
			let w = 100;
			let h = 100;

			console.log(this.settings)
			if (this.settings.height !== "inherit") {
				if (this.settings.height === "ratio") {
					var eRatio = false;
					try {
						var eRatio = await this.getRatio();
					}
					catch (e) {
						// nothing to do here
					}
					if (eRatio > 0) {
						this.style.height = (this.clientWidth / eRatio) + 'px';
						this.style.position = "relative";
					}
				}
				else if (this.settings.height) {
					this.style.height = this.settings.height
					this.style.position = "relative";
				}
			}

			if (this.settings.cover && this.root.clientHeight > 0) {
				var ratio = this.root.clientWidth / this.root.clientHeight;
				var eRatio = await this.getRatio()
				if (ratio < eRatio)
					w = w * eRatio / ratio;
				else
					h = h * ratio / eRatio;
			}
			this.root.querySelector('.wrapper').style.width = w + '%';
			this.root.querySelector('.wrapper').style.height = h + '%';
		}
		catch (e) {
			console.error('hapi-player __fixRatio halted', e);
			clearInterval(this.__fixRatio_Interval);
		}
	}
}
customElements.define('hapi-video', hapVideo)

function firstInterraction() {
	if (!__firstInterractionReady) {
		__firstInterractionReady = true;
		__instances.forEach((i) => {
			i.onReady()
		});
	}
}
document.addEventListener('mousedown', firstInterraction);
document.addEventListener('touchstart', firstInterraction);
document.addEventListener('keydown', firstInterraction);