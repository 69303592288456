import EventDispatcher from "js-class.event-dispatcher";
import playerjs from '@gumlet/player.js';

export class Provider {
	constructor(HTMLElement) {
		this.type = "Gumlet";
		this.HTMLElement = HTMLElement;
		this.events = new EventDispatcher(this);
		this.player = null;
		// private state
		this.__volume = null;
		this.__started = null;
		this.__progress = 0;
	}

	getEmbed() {
		if (this.HTMLElement?.settings?.embed?.id && !this.HTMLElement.querySelector('.embed')) {
			this.__embed = document.createElement('div');
			this.__embed.setAttribute('part', 'embed');
			this.__embed.setAttribute('class', 'embed');

			setTimeout(() => this.buildPlayer(), 1);

			return this.__embed;
		}
		else {
			return false;
		}
	}
	async buildPlayer() {
		if (!this.HTMLElement.root.querySelector('.iframe')) {
			this.player = null;
			const iframe = document.createElement('iframe');
			iframe.setAttribute('part', 'iframe');
			iframe.setAttribute('class', 'iframe');
			iframe.setAttribute('type', 'text/html');
			iframe.setAttribute('allow', 'accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture; fullscreen;');
			iframe.setAttribute('src', `https://play.gumlet.io/embed//${this.HTMLElement?.settings?.embed?.id}?preload=true&autoplay=true&loop=true&disable_player_controls=true`);

			this.__embed.appendChild(iframe);

			setTimeout(() => this.buildPlayer(), 100);
		}
		else if (!this.player) {
			this.player = new playerjs.Player(this.HTMLElement.root.querySelector('.iframe'))
			this.player.on('ready', () => {
				this.player.isReady = true
				if (!this.__started) {
					this.__started = setTimeout(() => this.fire('ready'), 1000);
					this.progressObserver_Interval = setInterval(this.progressObserver.bind(this), 100);
				}
			})
		}
	}
	async destroyPlayer() {
		if (this.player && typeof this.player.destroy === 'function') {
			this.player.destroy()
		}
	}

	async progressObserver() {
		try {
			if (await this.getDuration() == 0)
				return 0;

			let p = 100 * await this.getCurrentTime() / await this.getDuration();
			if (p !== this.__progress) {
				if (this.__started && this.__started !== true) {
					clearTimeout(this.__started)
					this.__started = true;
					this.fire('ready');
				}
				this.__progress = p;
				this.fire('progressChange');
			}
		}
		catch (e) {
			console.error(e);
			console.error('hapi-player Gumlet provider progressObserver halted');
			clearInterval(this.progressObserver_Interval);
		}
	}

	async getRatio() {
		return this.HTMLElement?.settings?.embed.ratio;
	}

	async getDuration() {
		if (this.player && typeof this.player.getDuration === 'function')
			return new Promise((resolve, reject) => this.player.getDuration((value) => {
				resolve(value)
			}))
		return 0;
	}

	async playing() {
		if (this.player)
			return !(await this.player.getPaused());
	}
	async play() {
		if (this.player)
			this.player.play()
	}
	async pause() {
		if (this.player)
			this.player.pause()
	}
	async getCurrentTime() {
		if (this.player && typeof this.player.getCurrentTime === 'function')
			return new Promise((resolve, reject) => this.player.getCurrentTime((value) => {
				resolve(value)
			}))
	}
	async setCurrentTime(seconds) {
		if (this.player)
			this.player.setCurrentTime(seconds)
	}
	async setVolume(volume) { // 0-100
		if (this.player) {
			this.__volume = volume;
			if (volume <= 0)
				this.player.mute(volume <= 0);
			else
				this.player.unmute(volume <= 0);
			this.player.setVolume(volume)
			setTimeout(() => this.fire('volumeChange'), 10)
		}
	}
	async getVolume() { // 0-100
		if (this.player)
			return new Promise((resolve, reject) => this.player.getVolume((value) => {
				resolve(value)
			}))
		return 0;
	}
	async mute() {
		if (this.player) {
			this.__volume = await this.getVolume()
			this.setVolume(0)
		}
	}
	async unMute(dflt = 100) {
		if (this.player) {
			this.setVolume(this.__volume > 0 || dflt)
		}
	}

	on(eventName, callback) { this.events.registerEvent(eventName, callback); }

	off(eventName, callback) { this.events.unregisterEvent(eventName, callback); }

	fire(eventName, data) {
		this.events.fireEvent(eventName, data);
	}
}

export default Provider;